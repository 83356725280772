import VueRouter from 'vue-router'

const ROUTES = {
    // Layout
    MAIN : {name:"Main", displayName:"PCoC Inventory Management", path:"/"},

    // Pages
    INVENTORY : {name:"INVENTORY", displayName:"รายการเวชภัณฑ์คงคลัง", path:"/inventory"},
    ASN : {name:"ASN", displayName:"รับเวชภัณฑ์", path:"/asn"},
    WITHDRAW : {name:"WITHDRAW", displayName:"เบิกเวชภัณฑ์", path:"/withdraw"},
    ASN_DETAIL : {name:"ASN_DETAIL", displayName:"รายละเอียดนัดหมายรับเวชภัณฑ์", path:"/asn-det"},
    WITHDRAW_DETAIL : {name:"WITHDRAW_DETAIL", displayName:"รายละเอียดการเบิกเวชภัณฑ์ตรวจเยี่ยม", path:"/withdraw-det"},
    STOCKCOUNT_DETAIL : {name:"STOCKCOUNT_DETAIL", displayName:"ตรวจสอบจำนวนเวชภัณฑ์", path:"/stockcount-det"},
    IMPORT : {name:"IMPORT", displayName:"นำเข้าเวชภัณฑ์เพิ่มเติม", path:"/import"},
    STOCK_CARD : {name:"STOCK_CARD", displayName:"พิมพ์สต๊อคการ์ด", path:"/stockcard"},
    RB301 : {name:"RB301", displayName:"พิมพ์แบบ รบ.301", path:"/rb301"},

}

const mainStack = {
    name: ROUTES.MAIN.name,
    path: ROUTES.MAIN.path,
    meta : {
        reqAuth : false,
    },
    component:()=>import('./layout/main'),
    children: [
        {
            name : ROUTES.INVENTORY.name,
            path : ROUTES.INVENTORY.path,
            meta : {
                reqAuth : false,
            },
            component:()=>import('./screen/inventory')
        },{
            name : ROUTES.ASN.name,
            path : ROUTES.ASN.path,
            meta : {
                reqAuth : false,
            },
            component:()=>import('./screen/asn')
        },{
            name : ROUTES.WITHDRAW.name,
            path : ROUTES.WITHDRAW.path,
            meta : {
                reqAuth : false,
            },
            component:()=>import('./screen/withdraw')
        },{
            name : ROUTES.ASN_DETAIL.name,
            path : ROUTES.ASN_DETAIL.path,
            meta : {
                reqAuth : false,
            },
            component:()=>import('./screen/asnDet')
        },{
            name : ROUTES.WITHDRAW_DETAIL.name,
            path : ROUTES.WITHDRAW_DETAIL.path,
            meta : {
                reqAuth : false,
            },
            component:()=>import('./screen/withdrawDet')
        },{
            name : ROUTES.STOCKCOUNT_DETAIL.name,
            path : ROUTES.STOCKCOUNT_DETAIL.path,
            meta : {
                reqAuth : false,
            },
            component:()=>import('./screen/stockcountDet')
        },{
            name : ROUTES.IMPORT.name,
            path : ROUTES.IMPORT.path,
            meta : {
                reqAuth : false,
            },
            component:()=>import('./screen/import')
        },{
            name : ROUTES.STOCK_CARD.name,
            path : ROUTES.STOCK_CARD.path,
            meta : {
                reqAuth : false,
            },
            component:()=>import('./screen/stockcard')
        },{
            name : ROUTES.RB301.name,
            path : ROUTES.RB301.path,
            meta : {
                reqAuth : false,
            },
            component:()=>import('./screen/rb301')
        }
    ]
}

const router = new VueRouter({
    routes : [
        mainStack,
    ]
})

export {router, ROUTES}